<template>
  <div>
    <b-card-actions title="Filters" action-collapse>
      <div>
        <b-row>
          <b-col md="6" lg="6" xs="12">
            <b-form-input
                v-on:keyup="onSearch"
                v-model.lazy="searchTerm"
                placeholder="Search By Invoice ID"
                type="text"
                class="d-inline-block mr-sm-1 mb-1 mb-sm-0"
            />
          </b-col>
          <!-- <b-col md="6" lg="4" xs="12">
            <v-select
              label="name"
              v-model="filterProject"
              :options="filterProjectIdOption"
              :reduce="(item) => item.id"
              v-on:input="filterByProject"
              placeholder="By Project Title"
              class="mr-sm-1 mb-1 mb-sm-0 custom-font"
            />
          </b-col> -->

          <b-col md="6" lg="6" xs="12">
            <v-select
                label="name"
                v-model="filterStatus"
                :options="statusTypeOptions"
                :reduce="(item) => item.value"
                v-on:input="filterByStatus"
                placeholder="By Status"
                class="mr-sm-1 mb-1 mb-sm-0 custom-font"
            />
          </b-col>
        </b-row>
      </div>
    </b-card-actions>
    <b-card>
      <div>
        <!-- table -->
        <vue-good-table
            mode="remote"
            styleClass="vgt-table table-custom-style striped"
            @on-page-change="onPageChange"
            @on-sort-change="onSortChange"
            @on-column-filter="onColumnFilter"
            @on-per-page-change="onPerPageChange"
            :totalRows="totalRecords"
            :isLoading.sync="isLoading"
            :rows="rows"
            :sort-options="{
          enabled: false,
          multipleColumns: true,
          initialSortBy: [
            { field: 'id', type: 'desc' },

            { field: 'created_at', type: 'desc' },
          ],
        }"
            :columns="columns"
            :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
        >
          <template slot="table-row" slot-scope="props">
            <template v-if="props?.column?.field === 'invoice_info'">
              <div class="table-cell-margin">
                <b-link :href="getPageUrl(props.row)" v-on:click="onShowPage(props?.row)">
                  {{ props?.row?.invoice_no }}
                </b-link>
              </div>
              <div class="table-cell-margin">
                Issue Date:
                <b><span>{{ formatDate(props?.row?.issue_date) }}</span></b>
              </div>
              <div class="table-cell-margin">
                Due Date:
                <b><span>{{ formatDate(props?.row?.due_date) }}</span></b>
              </div>
            </template>
            <template v-if="props?.column?.field === 'milestone_info'">
              <template v-if="props?.row?.milestone?.data">
                <div class="table-cell-margin">
                  <b-link>{{ props?.row?.milestone?.data?.title }}</b-link>
                </div>
                <div class="table-cell-margin">
                  Amount:
                  <b><FormatCurrency :amount="props?.row?.milestone?.data?.total_amount" :currency="props?.row?.milestone?.data?.currency" /></b>
                </div>
                <div class="table-cell-margin">
                  VAT:
                  <b><FormatCurrency :amount="props?.row?.milestone?.data?.total_vat_amount" :currency="props?.row?.milestone?.data?.currency" /></b>
                </div>
                <div class="table-cell-margin">
                  TAX:

                  <b><FormatCurrency :amount="props?.row?.milestone?.data?.total_tax_amount" :currency="props?.row?.milestone?.data?.currency" /></b>
                </div>
              </template>
            </template>

            <template v-if="props?.column?.field === 'format_currency'">
              <FormatCurrency :type="'currency'" :currency="props?.row?.currency" />
            </template>

            <template v-if="props?.column?.field === 'amount_info'">
              <div class="table-cell-margin">
                Sub Total Amount:
                <b><span>{{ props?.row?.sub_total_amount }}</span></b>
              </div>
              <div class="table-cell-margin">
                VAT Amount:

                <b><span>{{ props?.row?.vat_amount }}</span></b>
              </div>
              <div class="table-cell-margin">
                TAX Amount:

                <b><span>{{ props?.row?.tax_amount }}</span></b>
              </div>
              <div class="table-cell-margin">
                Grand Total Amount:
                <b><span>{{ props?.row?.grand_total_amount }} </span></b>
              </div>
            </template>
            <template v-if="props.column.field === 'status_info'">
              <b-badge
                  variant="light-success"
                  v-if="props?.row?.status == 'paid'"
              >
                Paid
              </b-badge>
              <b-badge variant="light-danger" v-else> Due </b-badge>
            </template>
            <!-- Column: Action -->
            <span v-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                  />
                </template>
                <template>
                  <b-dropdown-item v-on:click="onShowPage(props.row)">
                    <template v-if="props?.row?.status == 'paid'">
                      <feather-icon icon="EyeIcon" class="mr-50" />
                      <span>Show</span>
                    </template>
                    <template v-if="props?.row?.status == 'due'">
                      <feather-icon icon="Edit2Icon" class="mr-50" />
                      <span>Edit</span>
                    </template>
                  </b-dropdown-item>
                </template>

                <template>
                  <b-dropdown-item v-on:click="onDelete(props.row.id)">
                    <feather-icon icon="TrashIcon" class="mr-50" />
                    <span>Delete</span>
                  </b-dropdown-item>
                </template>
              </b-dropdown>
            </span>
          </span>

            <!-- Column: Common -->
            <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
          </template>

          <!-- pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> Showing 1 to </span>
                <b-form-select
                    v-model="pageLength"
                    :options="['10', '25', '50', '100', '500']"
                    class="mx-1"
                    @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>
              <div>
                <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="(value) => props.pageChanged({ currentPage: value })"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>

      <b-modal
          id="modal-division-form"
          centered
          :title="modelType == 'editModel' ? 'Edit' : 'Create'"
          hide-footer
          @hidden="hiddenModal"
          no-close-on-backdrop
      >
        <validation-observer ref="divisionValidation">
          <b-form v-on:submit.prevent="validationForm">
            <!-- name -->
            <b-form-group label="Name" label-for="name" class="required-label">
              <validation-provider
                  #default="{ errors }"
                  name="name"
                  vid="name"
                  rules="required|max:255"
              >
                <b-form-input
                    id="name"
                    type="text"
                    v-model="name"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Division"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- select head-->
            <b-form-group label="Head" label-for="head-id">
              <ValidationProvider
                  name="head_id"
                  v-slot="{ errors }"
                  vid="head_id"
              >
                <v-select
                    id="head-id"
                    placeholder="Choose a Head"
                    v-model="selectHeadId"
                    :options="headIdOption"
                    :reduce="(country) => country.id"
                    label="name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>

            <!-- loading button -->
            <template v-if="isDivisionFormSubmitLoading">
              <b-button class="float-right" variant="primary" disabled>
                <b-spinner small />
                Loading...
              </b-button>
            </template>

            <!-- submit button -->
            <template v-else>
              <b-button
                  type="submit"
                  class="float-right"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
              >
                Submit
              </b-button>
            </template>
          </b-form>
        </validation-observer>
      </b-modal>
    </b-card>
  </div>
</template>

  <script>
import {
  BCard,
  BLink,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BForm,
  BModal,
  BSpinner,
  BRow,
  BCol,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, max } from "@validations";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import {
  DIVISION_CREATE,
  DIVISION_EDIT,
  DIVISION_DELETE,
  DIVISION_TRASH_ACCESS,
} from "@/helpers/permissionsConstant";
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import FormatCurrency from '@/layouts/components/FormatCurrency.vue'

export default {
  name: "InvoicesView",
  components: {
    FormatCurrency,
    BForm,
    BLink,
    BButton,
    BCard,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BSpinner,
    BRow,
    BCol,
    BCardActions
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      DIVISION_CREATE,
      DIVISION_EDIT,
      DIVISION_DELETE,
      DIVISION_TRASH_ACCESS,
      modelType: "",
      divisionId: "",
      name: "",
      selectHeadId: "",
      headIdOption: [],
      pageLength: 10,
      filterStatus: "",
      filterProject: "",
      filterProjectIdOption: [],
      statusTypeOptions: [
        { name: "Due", value: "due" },
        { name: "Paid", value: "paid" },
      ],
      columns: [
        {
          label: "Invoice No",
          field: "invoice_info",
        },
        {
          label: "Currency",
          field: "format_currency",
          sortable: false,
        },
        {
          label: "Amount",
          field: "amount_info",
          sortable: false,
        },
        {
          label: "Milestone Info",
          field: "milestone_info",
          sortable: false,
        },
        {
          label: "Status",
          field: "status_info",
          sortable: false,
        },
        {
          label: "Created On",
          field: "created_at",
          formatFn: this.formatDate,
          sortable: false,
        },

        {
          label: "Action",
          field: "action",
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: "",
      delayTimer: null,
      isLoading: false,
      isDivisionFormSubmitLoading: false,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          { field: "id", type: "desc" },
          { field: "created_at", type: "desc" },
        ],
        page: 1,
        perPage: 10,
      },
    };
  },

  computed: {
    ...mapGetters({
      filters: "invoiceModule/getFilters",
      permissions: "userModule/getPermissions",
    }),
  },

  async created() {
    try {
      this.searchTerm = this.filters?.searchTerm;
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          icon: "BellIcon",
          variant: "danger",
          text: error?.response?.data?.message,
        },
      });
    }
  },

  methods: {
    formatDate(value) {
      if (value) {
        return this.$moment(value).format("MMM Do YYYY");
      }
      return "Not Set";
    },

    filterByStatus() {
      this.loadItems();
    },

    filterByProject() {
      this.loadItems();
    },

    showModal() {
      this.$bvModal.show("modal-division-form");
    },
    hiddenModal() {
      this.modelType = "";
      this.$bvModal.hide("modal-division-form");
      this.resetModal();
    },
    resetModal() {
      this.name = "";
      this.selectHeadId = "";
    },
    onShowPage(value) {
      const id = value?.id;
      const milestoneId = value?.milestone?.data?.id;

      this.$router.push({
        name: "admin-invoice-edit-details",
        params: { milestoneId, id },
      });
    },
    getPageUrl(value) {
      const id = value?.id;
      const milestoneId = value?.milestone?.data?.id;

      return this.$router.resolve({ name: "admin-invoice-edit-details", params: { milestoneId, id } }).href;
    },
    async onShow(value) {
      this.modelType = "editModel";
      this.name = value.name;
      this.divisionId = value.id;
      this.selectHeadId = value.head_id;

      this.showModal();
    },
    async onDelete(id) {
      this.$swal({
        title: "Warning!",
        text: "Are You Sure You Want To Delete This?",
        icon: "warning",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Ok",
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.$api.delete(`api/invoices/${id}/force-delete`);

            this.loadItems();

            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "Successfully Deleted",
              },
            });
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                variant: "danger",
                text: error?.response?.data?.message,
              },
            });
          }
        }
      });
    },

    onSearch() {
      this.$store.dispatch("invoiceModule/setFilter", {
        key: "searchTerm",
        value: this.searchTerm,
      });

      if (this.delayTimer) {
        clearTimeout(this.delayTimer);
        this.delayTimer = null;
      }

      this.delayTimer = setTimeout(() => {
        this.loadItems();
      }, 1000);
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        sort: params,
      });
      this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },
    goToTrash() {
      this.$router.push({ name: "admin-division-trash" });
    },
    async getDivisions(params) {
      return await this.$api.get("api/divisions?include=head", {
        params: {
          show: params.show,
          page: params.page,
          sort: params.sort,
          q: params.q,
        },
      });
    },
    // async getAllMilestones() {
    //   return await this.$api.get("api/milestones/all");
    // },
    async getInvoices(params) {
      return await this.$api.get("api/invoices?include=milestone", {
        params: {
          show: params.show,
          page: params.page,
          sort: params.sort,
          q: params.q,
          filterStatus: params.filterStatus,
          filterProject: params.filterProject,
        },
      });
    },
    async loadItems() {
      try {
        const [invoiceInfo] = await Promise.all([
          this.getInvoices({
            show: this.serverParams.perPage,
            page: this.serverParams.page,
            sort: this.serverParams.sort,
            q: this.searchTerm,
            filterStatus: this.filterStatus,
            filterProject: this.filterProject,
          }),
        ]);

        const data = invoiceInfo?.data?.data;

        console.log(data);
        const meta = invoiceInfo?.data?.meta;

        this.totalRecords = meta?.pagination?.total;
        this.rows = data;
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
    validationForm: async function () {
      this.$refs.divisionValidation.validate().then(async (success) => {
        if (success) {
          try {
            if (this.modelType == "editModel") {
              this.isDivisionFormSubmitLoading = true;
              await this.$api.put(`/api/divisions/${this.divisionId}`, {
                name: this.name,
                head_id: this.selectHeadId,
              });
              this.isDivisionFormSubmitLoading = false;
              this.loadItems();
              this.hiddenModal();
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: "Successfully Updated",
                },
              });
            } else {
              this.isDivisionFormSubmitLoading = true;
              await this.$api.post(
                "/api/divisions",

                {
                  name: this.name,
                  head_id: this.selectHeadId,
                }
              );
              this.isDivisionFormSubmitLoading = false;
              this.hiddenModal();

              this.loadItems();

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: "Successfully Created",
                },
              });
            }
          } catch (error) {
            this.isDivisionFormSubmitLoading = false;
            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: error?.response?.data?.message,
                },
              });
            }

            if (error?.response?.data?.errors) {
              this.$refs.divisionValidation.setErrors(
                error?.response?.data?.errors
              );
            }
          }
        }
      });
    },
  },
};
</script>

  <style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

.required-label label::after {
  content: " *";
  color: red;
}

.table-custom-style {
  font-size: 13px !important;
  white-space: nowrap !important;
  min-height: 140px !important;
  tr,
  th,
  td {
    vertical-align: left !important;
    text-align: left !important;
  }
}

.table-cell-margin {
  margin: 8px 0px;
}
</style>
